<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @changed="getStudents"
              @onShowDetails="onShowDetails"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student_number',
            mask: 'Student Number',
          },
          {
            title: 'full_name',
            mask: 'full name',
          },
          {
            title: 'email',
          },
          {
            title: 'last_enrolment',
            mask: 'last enrolment',
          },
          {
            title: 'tuition_finish_date',
            mask: 'tuition finish date',
            sortable: true,
          },
          {
            title: 'exam_type',
            mask: 'exam type',
          },
          {
            title: 'days_left',
            mask: 'days left',
          },
          {
            title: 'exam_paid',
            mask: 'exam paid',
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Go to Student Profile',
            callback: 'onShowDetails',
            icon: 'person',
          },
        ],
        values: {},
      },
      loading: false,
    };
  },
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getStudents(params) {
      this.loading = true;
      this.request(
        'students_exam/report',
        'get',
        { ...params },
        ({ data }) => {
          this.vTable.values = data;
        },
        () => {
          this.loading = false;
        },
      );
    },
    onDownload() {
      this.loading = true;
      this.request(
        'students_exam/report',
        'download',
        { download: true },
        ({ data }) => {
          const filename = 'Students_exam_report.xlsx';
          downloadFile(data, filename);
        },
        () => {
          this.loading = false;
        },
      );
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.student_id },
      }, isMiddleClick);
    },
  },
};
</script>

<style scoped></style>
